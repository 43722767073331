import React from "react"

const Advantage = () => (
    <div className="advantage">
        <h4 className="advantage__header">
            Advantage
        </h4>
        <p className="advantage__description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum sodales justo eu efficitur.
        </p>
    </div>
)

export default Advantage