import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import RightArrow from "../../images/svg/right-arrow.svg";
import imageUrl from "../../helpers/flotiqImage";

const ServiceTile = ({service}) => {
    let serviceTileStyle = {
        backgroundImage: `url(${imageUrl(service.image)})`
    }

    return (
        <AniLink duration={.5} fade to={`/service/${service.slug}`}
                 className="service-tile">
            <div className="service-tile__image" style={serviceTileStyle}/>
            <div className="text-content">
                <h2 className="text-content__header">
                    {service.name}
                </h2>
                <div className="text-content__description"
                     dangerouslySetInnerHTML={{__html: service.shortDescription}}></div>
                <div className="text-content__read-more">
                    <p className="read-more-text">read more</p>
                    <RightArrow/>
                </div>
            </div>
        </AniLink>
    )
}

export default ServiceTile