import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ServicesIntroSection from "../sections/services/services-intro-section";
import CTASection from "../sections/cta-section";
import ServicesTilesSection from "../sections/services/services-tiles-section";
import ServicesTechnologiesSection from "../sections/services/services-technologies-section";
import ServicesAdvantagesSection from "../sections/services/services-advantages-section";

const ServicesPage = () => {


    return (
        <Layout>
            <SEO title="Services"/>
            <div className="subpage-main-wrapper">
                <ServicesIntroSection/>
                <ServicesTilesSection/>
                <ServicesTechnologiesSection/>
                <ServicesAdvantagesSection/>
            </div>
            <CTASection/>
        </Layout>
    )
}

export default ServicesPage
