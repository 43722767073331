import {graphql, useStaticQuery} from "gatsby"
import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
const CTASection = () => {
    const data = useStaticQuery(graphql`
        query {
            sectionBG: file(relativePath: { eq: "backgrounds/cdwv-bg-5.svg" }) {
                publicURL
            }
        }
    `);

    let CTASectionStyle = {
        background: `radial-gradient(ellipse at center, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 90%,#F9FCFC 100%), url(${data.sectionBG.publicURL})`
    }
    return (
        <section className="cw-container-fluid" style={CTASectionStyle}>
            <div className="cta-section">
                <h2 className="section-header">Build your project with CodeWave</h2>
                <div className="section-description">
                    <p>
                        Feel free to schedule a call with us and tell us about your
                        problems - we'll try to help!
                    </p>
                </div>
                <AniLink duration={.5} fade to="/contact" className="btn">Estimate your project</AniLink>
            </div>
        </section>
    )
}

export default CTASection