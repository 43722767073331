import React from "react"
import ServiceTile from "../../components/services/service-tile";
import {graphql, useStaticQuery} from "gatsby";

const ServicesTilesSection = () => {
    const data = useStaticQuery(graphql`
        query {
            serviceTilesSectionBG: file(relativePath: { eq: "backgrounds/cdwv-bg-4.jpg" }) {
                publicURL
            }
            services: allCodewaveService(filter: {isPublic: {eq: true}}) {
                nodes {
                    name
                    image {
                        id
                        extension
                    }
                    slug
                    shortDescription
                }
            }
        }
    `);

    let serviceTilesSectionStyle = {
        background: `url(${data.serviceTilesSectionBG.publicURL})`
    }
    const services = data.services.nodes;
    return (
        <section className="cw-container-fluid" style={serviceTilesSectionStyle}>
            <div className="cw-container content-section service-tiles-section">
                {
                    services.map(service => 
                        <ServiceTile key={service.slug} service={service}/>
                    )
                }
            </div>
        </section>
    )
}

export default ServicesTilesSection