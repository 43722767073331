import React from "react"
import Technology from "../../components/technology";
import {graphql, useStaticQuery} from "gatsby";

const ServicesTechnologiesSection = () => {
    const data = useStaticQuery(graphql`
    query {
        technologiesSectionBG: file(relativePath: { eq: "backgrounds/cdwv-bg-4.jpg" }) {
            publicURL
        }
        techs: allCodewaveTechnology(filter: {isPublic: {eq: true}}) {
            nodes {
                name
                id
                logo {
                    extension
                    id
                }
            }
        }
    }
    `);
    const techs = data.techs.nodes;

    let technologiesSectionStyle = {
        background: `url(${data.technologiesSectionBG.publicURL})`
    }

    return (
        <section className="cw-container-fluid" style={technologiesSectionStyle}>
            <div className="cw-container content-section services-technologies-section">
                <h2 className="section-header section-header--colored">Technologies</h2>
                <p className="section-description section-description--condensed">
                    Technologies we use everyday
                </p>
                <div className="technologies">
                {
                    techs.map(technology => <Technology key={technology.id} technology={technology}/>)
                }
                </div>
            </div>
        </section>
    )
}

export default ServicesTechnologiesSection