import React from "react"
import Advantage from "../../components/advantage";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import PartnersLogotypes from "../../components/partners-logotypes";

const ServicesAdvantagesSection = () => {
    const data = useStaticQuery(graphql`
            query {
                partnersLogotypes: file(relativePath: { eq: "partners-logotypes/partners.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 700, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                },                
                advantagesPhoto: file(relativePath: { eq: "services/services-advantages-image.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 700, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                },
                advantagesSectionBG: file(relativePath: { eq: "backgrounds/cdwv-bg-8.svg" }) {
                    publicURL
                }
            }
    `)

    let advantagesSectionStyle = {
        background: `url(${data.advantagesSectionBG.publicURL})`
    }

    return (
        <section className="cw-container-fluid" style={advantagesSectionStyle}>
            <div className="cw-container content-section services-advantages-section">
                <div className="services-advantages-section__content-column">
                    <h2 className="section-header">When working with us,<br/>you can expect more</h2>
                    <div className="advantages-wrapper">
                        <Advantage/>
                        <Advantage/>
                        <Advantage/>
                        <Advantage/>
                        <Advantage/>
                        <Advantage/>
                    </div>
                    <PartnersLogotypes/>
                </div>
                <div className="services-advantages-section__photo-column">
                    <Img className="photo" fluid={data.advantagesPhoto.childImageSharp.fluid} />
                </div>
            </div>
        </section>
    )
}

export default ServicesAdvantagesSection