import React from "react"
import imageUrl from "../helpers/flotiqImage";
const Technology = ({technology}) => {
    return (
        <div className="technology">
            <img className="technology__logo" alt={technology.name} src={imageUrl(technology.logo, {width: 250})} />
            {/*<p className="technology__name">*/}
            {/*{technology.name}*/}
            {/*</p>*/}
        </div>
    )
}

export default Technology