import React from "react"
import {graphql, useStaticQuery} from "gatsby";

const ServicesIntroSection = () => {
    const data = useStaticQuery(graphql`
        query {
            servicesIntroSectionBG: file(relativePath: { eq: "backgrounds/cdwv-bg-intro-alt.svg" }) {
                publicURL
            }
        }
    `);

    let servicesIntroSectionStyle = {
        background: `url(${data.servicesIntroSectionBG.publicURL})`
    }

    return (
        <section className="cw-container-fluid" style={servicesIntroSectionStyle}>
            <div className="cw-container subpage-intro-section">
                <h1>Complete development teams<br/>Ready to build the product you
                    need</h1>
            </div>
        </section>
    )
}

export default ServicesIntroSection